import { useContext } from 'react';
import { RiDeleteBinFill } from 'react-icons/ri';

import { FiltersContext } from '../../contexts/FiltersContext';
import { TasksFiltersContext } from '../../contexts/TasksFiltersContext';

const TasksFilterList = () => {
  const { filterTasksNames } = useContext(TasksFiltersContext);
  const { selectedTaskFilter, setSelectedTaskFilter } =
    useContext(FiltersContext);

  return (
    <>
      {/* Display the list of filters on SidebarNav */}
      <div className='sidebar-filter-container gap-2'>
        {filterTasksNames.map((filteredItem) => (
          <div key={filteredItem} className='sidebar-filter-item'>
            <div
              className='sidebar-filter-item-title'
              type='button'
              aria-pressed={filteredItem === selectedTaskFilter}
              onClick={() => setSelectedTaskFilter(filteredItem)}>
              <h3 className='d-flex gap-2 fs-6 py-1'>
                <RiDeleteBinFill className='d-flex fs-6' />
                {filteredItem}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TasksFilterList;
