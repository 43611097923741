import { useContext } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { TasksContext } from '../../contexts/TasksContext';

const BtnCompleteTask = ({ task }) => {
  const { completeTask } = useContext(TasksContext);

  return (
    <>
      <FiCheckCircle
        className={`fs-3 ${
          task.isComplete ? 'text-gray-500' : 'text-gray-600'
        } `}
        role='button'
        aria-label='Complete Task'
        title='Complete Task'
        onClick={() => completeTask(task.id)}
      />
    </>
  );
};

export default BtnCompleteTask;
