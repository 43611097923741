import PinIcon from '../utilities/PinIcon';
import BtnCompleteTask from '../buttons/BtnCompleteTask';
import BtnEditTask from '../buttons/BtnEditTask';
import BtnOpenDeleteModal from '../buttons/BtnOpenDeleteModal';
import { useContext } from 'react';
import { CategoriesContext } from '../../contexts/CategoriesContext';

const Task = ({ task }) => {
  const { taskCategories } = useContext(CategoriesContext);

  const taskCategory = taskCategories.find(
    (tc) => tc.id === task.taskCategoryId
  );

  const categoryColor = taskCategory.taskCategoryColor;

  return (
    <>
      <div className='task-item featured-border d-flex gap-3 flex-column p-2'>
        <div className='w-100 d-flex justify-content-between'>
          <BtnCompleteTask task={task} />

          <PinIcon categoryColor={categoryColor} task={task} />
        </div>

        <div className='d-flex gap-2 flex-column'>
          <p
            className={`lh-sm ${
              task.isComplete ? 'text-gray-500' : categoryColor
            } `}>
            {task.taskTitle}
          </p>

          <p
            className={`fs-sm ${
              task.isComplete ? 'text-gray-500' : 'text-dark'
            } `}>
            {new Date(task.taskDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: '2-digit',
              month: 'short',
            })}
          </p>

          <p
            className={`fs-sm ${
              task.isComplete ? 'text-gray-500' : 'text-dark'
            } `}>
            {taskCategory.taskCategoryTitle}
          </p>
        </div>

        <div className='d-flex justify-content-between gap-4 after-bg'>
          <BtnEditTask task={task} />

          <BtnOpenDeleteModal task={task} />
        </div>
      </div>
    </>
  );
};

export default Task;
