import { slide as Menu } from 'react-burger-menu';
import { RiMenuFoldLine } from 'react-icons/ri';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import { BsPatchPlus } from 'react-icons/bs';
import { BsPatchMinus } from 'react-icons/bs';
import { RiArrowDownSLine } from 'react-icons/ri';
import { RiArrowRightSLine } from 'react-icons/ri';

import Accordion from '../utilities/Accordion';
import AddTaskCategoryForm from '../categories/AddTaskCategoryForm';
import CategoriesFilterList from '../categories/CategoriesFilterList';
import FavoritesNav from './FavoritesNav';
import TasksFilterList from '../tasks/TasksFilterList';
import UtilNav from './UtilNav';

const SidebarNav = () => {
  return (
    <>
      <Menu
        right
        customBurgerIcon={
          <div className='fs-1 align-all'>
            <RiMenuFoldLine />
          </div>
        }
        customCrossIcon={
          <div className='fs-1 px-3 pt-3'>
            <RiMenuUnfoldLine />
          </div>
        }>
        <hr className='text-light' />

        <div className='util-nav-wrapper'>
          <UtilNav />
        </div>

        <hr className='text-light' />

        <div className='favorites-nav-wrapper'>
          <h2 className='font-styled fs-6 text-light mb-3'>Favorites</h2>

          <FavoritesNav />
        </div>

        <hr className='text-light' />

        <div className='categories-nav-wrapper'>
          <Accordion
            title='Categories'
            iconOpen={<BsPatchPlus />}
            iconClose={<BsPatchMinus />}>
            <AddTaskCategoryForm />
          </Accordion>

          <CategoriesFilterList />
        </div>

        <hr className='text-light' />

        <div className='filters-nav-wrapper'>
          <Accordion
            title='Filters'
            iconOpen={<RiArrowDownSLine />}
            iconClose={<RiArrowRightSLine />}>
            <TasksFilterList />
          </Accordion>
        </div>

        <hr className='text-light' />
      </Menu>
    </>
  );
};

export default SidebarNav;
