const Footer = () => {
  // Display current year
  const currentYear = new Date();
  const yyyy = {
    year: 'numeric',
  };

  return (
    <>
      <p className='lh-sm text-dark text-center mb-1'>
        Developed by Vanessa and Marcelo Barsotti
      </p>
      <p className='lh-sm text-dark text-center'>
        {currentYear.toLocaleDateString('en-US', yyyy)} &copy; All rights
        reserved
      </p>
    </>
  );
};

export default Footer;
