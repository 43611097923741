import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { GiNotebook } from 'react-icons/gi';

export function UserGreeting() {
  const { accounts } = useMsal();

  return (
    <>
      <AuthenticatedTemplate>
        <section className='align-all flex-column vh-80 text-dark'>
          <p className='display-5 font-styled text-center text-blue-200 mb-3'>
            Hello, <br /> {accounts[0].name}!
          </p>
          <p className='fs-5 text-center lh-sm'>
            Let's get organized <br /> and have some
          </p>

          <h1 className='display-5 font-styled text-dark py-3'>
            <span className='text-primary'>Fun </span>
            <span className='text-secondary'>With </span>
            <span className='text-blue-200'>Tasks </span>
          </h1>

          <Link
            to='/tasks'
            className='d-flex link-primary fs-xl'
            aria-label='Tasks list page'
            title='Tasks list'>
            <GiNotebook />
          </Link>
        </section>
      </AuthenticatedTemplate>
    </>
  );
}
