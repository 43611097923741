import { createContext, useContext, useState } from 'react';

import { CategoriesContext } from './CategoriesContext';
import { FiltersContext } from './FiltersContext';
import { TasksContext } from './TasksContext';

import Task from '../components/tasks/Task';
import { useEffect } from 'react';

export const TasksFiltersContext = createContext();

const TasksFiltersContextProvider = ({ children }) => {
  const { taskCategories } = useContext(CategoriesContext);
  const { selectedTaskFilter, selectedTaskCategoryFilter } = useContext(FiltersContext);
  const { tasks } = useContext(TasksContext);
  const [filterCategories, setFilterCategories] = useState({ 'All Categories': () => true });
  const [filterCategoriesNames, setFilterCategoriesNames] = useState(Object.keys(filterCategories));

  useEffect(() => {
    const loadCategoriesFilter = () => {
      const categoriesFilters = taskCategories.map((tc) => {
        return {
          categoryName: tc.taskCategoryTitle,
          filter: (task) => task.taskCategoryId === tc.id,
        };
      });
      const fcs = filterCategories;
      categoriesFilters.forEach((cf) => {
        fcs[cf.categoryName] = cf.filter;
      });
      setFilterCategories(fcs);
      setFilterCategoriesNames(Object.keys(filterCategories));
    };
    loadCategoriesFilter();
  }, [taskCategories, filterCategories]);

  // Function to find if it is Yesterday, Today, or Tomorrow
  const isOnDay = (date, daysFromToday) => {
    if (!date) return false;

    let dayToCheck = new Date();
    dayToCheck.setDate(dayToCheck.getDate() + daysFromToday);

    return (
      date.getDate() === dayToCheck.getDate() &&
      date.getMonth() === dayToCheck.getMonth() &&
      date.getFullYear() === dayToCheck.getFullYear()
    );
  };

  // Function to find if it is Last Week, This Week, or Next Week
  const isOnWeek = (date, weeksFromToday) => {
    if (!date) return false;

    const firstDayOfTheWeek = findFirstDayOfTheWeek(weeksFromToday - 1);
    const firstDayOfFollowingWeek = findFirstDayOfTheWeek(weeksFromToday);

    return date >= firstDayOfTheWeek && date < firstDayOfFollowingWeek;
  };

  // Function to find the first day of the week
  const findFirstDayOfTheWeek = (weeksFromToday) => {
    const monday = 1;
    let day = new Date();

    setTimeToZero(day);

    day.setDate(day.getDate() + 7 * weeksFromToday);

    while (day.getDay() !== monday) {
      day.setDate(day.getDate() + 1);
    }

    return day;
  };

  // Function to set hour, minute, second, and millisencond to zero
  const setTimeToZero = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  };

  // Function to find if it is Last Month, This Month, or Next Month
  const isOnMonth = (date, monthsFromToday) => {
    if (!date) return false;

    let monthToCheck = new Date();
    monthToCheck.setMonth(monthToCheck.getMonth() + monthsFromToday);

    return date.getMonth() === monthToCheck.getMonth();
  };

  // Filter map for tasks
  const filterTasks = {
    'All Tasks': () => true,
    Active: (task) => !task.isComplete,
    Completed: (task) => task.isComplete,
    Yesterday: (task) => isOnDay(new Date(task.taskDate), -1),
    Today: (task) => isOnDay(new Date(task.taskDate), 0),
    Tomorrow: (task) => isOnDay(new Date(task.taskDate), 1),
    'Last Week': (task) => isOnWeek(new Date(task.taskDate), -1),
    'This Week': (task) => isOnWeek(new Date(task.taskDate), 0),
    'Next Week': (task) => isOnWeek(new Date(task.taskDate), 1),
    'Last Month': (task) => isOnMonth(new Date(task.taskDate), -1),
    'This Month': (task) => isOnMonth(new Date(task.taskDate), 0),
    'Next Month': (task) => isOnMonth(new Date(task.taskDate), 1),
  };

  // Filter names for tasks
  const filterTasksNames = Object.keys(filterTasks);

  // Display filtered task title
  const filteredTaskTitle = filterTasksNames.map((filteredTask) =>
    filteredTask === selectedTaskFilter ? filteredTask : ''
  );

  // Display filtered task category title
  const filteredCategoryTitle = filterCategoriesNames.map((filteredCategory) =>
    filteredCategory === selectedTaskCategoryFilter ? filteredCategory : ''
  );

  // Display filtered list of tasks
  const filteredTasksList = tasks
    .filter(filterTasks[selectedTaskFilter])
    .filter(filterCategories[selectedTaskCategoryFilter])
    .map((task) => <Task key={task.id} task={task} />);

  return (
    <TasksFiltersContext.Provider
      value={{
        filterTasksNames,
        filteredTaskTitle,
        filterCategoriesNames,
        filteredCategoryTitle,
        filteredTasksList,
      }}
    >
      {children}
    </TasksFiltersContext.Provider>
  );
};

export default TasksFiltersContextProvider;
