import { useContext } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { PopupContext } from '../../contexts/PopupContext';

const BtnAddTask = () => {
  const { showModal } = useContext(PopupContext);

  return (
    <>
      <div className='btn-add-task btn-border'>
        <button
          className='btn-border-inner btn btn-sm d-flex gap-2 align-items-center justify-content-center'
          type='button'
          aria-label='Add Task'
          title='Add Task'
          onClick={showModal}>
          <p className='link-primary'>Add Task</p>
          <FiPlusCircle className='fs-4 link-primary' />
        </button>
      </div>
    </>
  );
};

export default BtnAddTask;
