import { createContext, useEffect, useState, useContext } from 'react';
import api from '../api/taskcategories';
import { PopupContext } from './PopupContext';
import { TasksContext } from './TasksContext';
import { useMsal } from '@azure/msal-react';
import { getToken, getApiConfig } from '../utilities/SecurityUtils';

export const CategoriesContext = createContext();

const CategoriesContextProvider = ({ children }) => {
  const { instance, accounts } = useMsal();
  const { deleteIsOpen, setDeleteIsOpen } = useContext(PopupContext);
  const { tasks } = useContext(TasksContext);

  const [taskCategories, setTaskCategories] = useState([]);
  const [taskCategoryToDelete, setTaskCategoryToDelete] = useState(null);

  useEffect(() => {
    const fetchTaskCategories = async () => {
      if (!instance || !accounts || accounts.length === 0) {
        setTaskCategories([]);
        return;
      }
      try {
        const token = await await getToken(instance, accounts);
        const config = getApiConfig(token);
        const res = await api.get('/taskcategories', config);
        setTaskCategories(res.data);
      } catch (error) {
        console.log(`Error: ${error.message}`);
      }
    };
    fetchTaskCategories();
  }, [instance, accounts]);

  // Add task category function
  const addTaskCategory = async (newTaskCategory) => {
    try {
      const token = await getToken(instance, accounts);
      const config = getApiConfig(token);
      const res = await api.post('/taskcategories', newTaskCategory, config);
      setTaskCategories([...taskCategories, res.data]);
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  // Delete task category function
  const deleteTaskCategory = async (id) => {
    try {
      const token = await getToken(instance, accounts);
      const config = getApiConfig(token);
      await api.delete(`/taskcategories/${id}`, config);
      setTaskCategories(taskCategories.filter((taskCategory) => taskCategory.id !== id));
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  // Define task category to delete
  const getTaskCategoryToDelete = (id) => {
    return taskCategories.find((taskCat) => taskCat.id === id);
  };

  const handleDeleteTaskCategory = (id) => {
    const findTask = tasks.find((tas) => tas.id !== id);
    console.log(findTask);
    setTaskCategoryToDelete(getTaskCategoryToDelete(id));

    setDeleteIsOpen(!deleteIsOpen);
  };

  // Define task category to edit

  return (
    <CategoriesContext.Provider
      value={{
        taskCategories,
        setTaskCategories,
        addTaskCategory,
        deleteTaskCategory,
        taskCategoryToDelete,
        handleDeleteTaskCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContextProvider;
