import { useState, useContext, forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { GiCalendar } from 'react-icons/gi';
import { CategoriesContext } from '../../contexts/CategoriesContext';
import { PopupContext } from '../../contexts/PopupContext';
import { TasksContext } from '../../contexts/TasksContext';
import { getDefaultTaskCategoryId } from './TaskCategoriesHelper';

const AddTaskForm = () => {
  const { taskCategories } = useContext(CategoriesContext);
  const { isOpen, setIsOpen, hideModal } = useContext(PopupContext);
  const { addTask } = useContext(TasksContext);

  const [taskTitle, setTaskTitle] = useState('');
  const [taskDate, setTaskDate] = useState(new Date());
  const [selectedTaskCategory, setSelectedTaskCategory] = useState(getDefaultTaskCategoryId(taskCategories));

  const onSubmit = (e) => {
    e.preventDefault();

    if (!taskTitle) {
      alert('Add a new task');
      return;
    }

    const newTask = {
      taskTitle,
      taskDate,
      taskCategoryId: selectedTaskCategory,
    };

    addTask(newTask);
    setTaskTitle('');
    setTaskDate(new Date());
    setSelectedTaskCategory(getDefaultTaskCategoryId(taskCategories));
    setIsOpen(!isOpen);
  };

  const CustomBtnCalendar = forwardRef(({ onClick }, ref) => (
    <div
      className='btn-calendar d-flex align-items-center link-primary'
      role='button'
      aria-label='Calendar'
      title='Calendar'
      onClick={onClick}
      ref={ref}
    >
      <GiCalendar />
    </div>
  ));

  return (
    <>
      <form onSubmit={onSubmit} className='add-task-form custom-form-style d-flex gap-3 flex-column'>
        <div className='add-task-input d-flex gap-2 flex-column'>
          <label className='text-primary'>Add new task</label>

          <input
            className='form-control input-style'
            type='text'
            placeholder='Today I will...'
            aria-label='Add new task input'
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
          />

          <div className='date-picker-content'>
            <DatePicker
              customInput={<CustomBtnCalendar />}
              popperClassName='datePickerCustomClass'
              value={taskDate}
              selected={taskDate}
              onChange={(date) => setTaskDate(date)}
            />
          </div>
        </div>

        <div className='add-category-select d-flex gap-2 flex-column'>
          <label className='text-primary'>Select a category</label>

          <select
            className='form-select'
            defaultValue={taskCategories.find((tc) => tc.taskCategoryTitle === 'Inbox').id}
            aria-label='Select category'
            onChange={(e) => setSelectedTaskCategory(e.target.value)}
          >
            {taskCategories.map((taskCategory) => {
              return (
                <option key={taskCategory.id} value={taskCategory.id}>
                  {taskCategory.taskCategoryTitle}
                </option>
              );
            })}
          </select>
        </div>

        <div className='add-form-btns btn-border d-flex gap-2 justify-content-center'>
          <div className='btn-save-task btn btn-sm btn-border-inner'>
            <input className='link-primary' type='submit' value='Save' />
          </div>

          <div className='btn-close-task btn btn-sm btn-border-inner'>
            <input className='link-primary' type='button' value='Close' onClick={hideModal} />
          </div>
        </div>
      </form>
    </>
  );
};

export default AddTaskForm;
