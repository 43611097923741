import { useContext } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Modal from 'react-bootstrap/Modal';
import useWindowSize from '../../services/useWindowSize';

import { PopupContext } from '../../contexts/PopupContext';
import { TasksContext } from '../../contexts/TasksContext';

import AddTaskForm from '../tasks/AddTaskForm';
import BtnAddTask from '../buttons/BtnAddTask';
import DateTimeDisplay from '../utilities/DateTimeDisplay';
import EditTaskForm from '../tasks/EditTaskForm';
import TasksList from '../tasks/TasksList';
import UnauthenticatedWarning from '../utilities/UnauthenticatedWarning';

const TasksPage = ({ pageTitle }) => {
  const { deleteTask, taskToDelete } = useContext(TasksContext);

  const {
    isOpen,
    setIsOpen,
    hideModal,
    editIsOpen,
    showEditModal,
    deleteIsOpen,
    setDeleteIsOpen,
  } = useContext(PopupContext);

  // Define screen size limit
  const [width] = useWindowSize();
  const isSmallDevice = width < 576;

  return (
    <>
      <AuthenticatedTemplate>
        {isSmallDevice && (
          <>
            <DateTimeDisplay />
            <hr />
          </>
        )}

        <div className='page-header d-flex justify-content-between align-items-center px-3'>
          <h2 className='page-title'>{pageTitle}</h2>

          <BtnAddTask />
        </div>

        <hr />

        <TasksList />

        {isOpen && (
          // Open Add New Task popup
          <Modal show={isOpen} contentClassName={'modal-popup'}>
            <div className='add-task-popup featured-border'>
              <Modal.Header>
                <Modal.Title>
                  <h4 className='text-primary font-styled'>Add a New Task</h4>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <AddTaskForm
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  hideModal={hideModal}
                />
              </Modal.Body>
            </div>
          </Modal>
        )}

        {editIsOpen && (
          // Open Edit Task popup
          <>
            <Modal show={showEditModal} contentClassName={'modal-popup'}>
              <div className='edit-task-popup featured-border'>
                <Modal.Header>
                  <Modal.Title>
                    <h4 className='text-primary font-styled'>Edit Task</h4>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <EditTaskForm />
                </Modal.Body>
              </div>
            </Modal>
          </>
        )}

        {deleteIsOpen && (
          // Open Confirm Delete Task popup
          <Modal show={deleteIsOpen} contentClassName={'modal-popup'}>
            <div className='delete-task-popup featured-border'>
              <Modal.Header>
                <Modal.Title>
                  <h4 className='text-primary font-styled'>Confirm delete?</h4>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body className='btn-border d-flex gap-2 justify-content-center'>
                <div className='btn-confirm-delete btn btn-sm btn-border-inner'>
                  <button
                    className='link-primary'
                    aria-label='Delete Task'
                    title='Delete Task'
                    onClick={() => {
                      deleteTask(taskToDelete.id);
                      setDeleteIsOpen(!deleteIsOpen);
                    }}>
                    Confirm
                  </button>
                </div>

                <div className='btn-cancel-delete btn btn-sm btn-border-inner'>
                  <button
                    className='link-primary'
                    aria-label='Delete Task'
                    title='Delete Task'
                    onClick={() => setDeleteIsOpen(!deleteIsOpen)}>
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedWarning />
    </>
  );
};

export default TasksPage;
