import { silentRequest } from '../authConfig';

export const getToken = async (instance, accounts) => {
  try {
    const response = await instance.acquireTokenSilent({
      ...silentRequest,
      account: accounts[0],
    });
    return response.accessToken;
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const getApiConfig = (token) => {
  return { headers: { Authorization: `Bearer ${token}` } };
};
