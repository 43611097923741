import { UnauthenticatedTemplate } from '@azure/msal-react';

const UnauthenticatedWarning = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <p className='fs-3 font-styled text-primary text-center mx-3'>
          Please, login to see your information.
        </p>
      </UnauthenticatedTemplate>
    </>
  );
};

export default UnauthenticatedWarning;
