import { useContext } from 'react';

import { FiltersContext } from '../../contexts/FiltersContext';
import { TasksFiltersContext } from '../../contexts/TasksFiltersContext';

const CategoriesFilterList = () => {
  const { selectedTaskCategoryFilter, setSelectedTaskCategoryFilter } =
    useContext(FiltersContext);
  const { filterCategoriesNames } = useContext(TasksFiltersContext);

  return (
    <>
      {/* Display the list of filters on SidebarNav */}
      <div className='sidebar-filter-container gap-2 mt-1'>
        {filterCategoriesNames.map((filteredItem) => (
          <div key={filteredItem} className='sidebar-filter-item'>
            <div
              className='sidebar-filter-item-title'
              type='button'
              aria-pressed={filteredItem === selectedTaskCategoryFilter}
              onClick={() => setSelectedTaskCategoryFilter(filteredItem)}>
              <h3 className='d-flex gap-2 fs-6 py-1'>{filteredItem}</h3>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CategoriesFilterList;
