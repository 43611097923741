import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { GiExitDoor } from 'react-icons/gi';

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

// Renders a button which, when selected, will open a popup for login
export const BtnLogin = () => {
  const { instance } = useMsal();

  return (
    <>
      <div
        className='btn-util-nav-link'
        role='button'
        aria-label='Sign in'
        title='Sign in'
        onClick={() => {
          handleLogin(instance);
        }}>
        <GiExitDoor />
        <h3 className='fs-xs'>Login</h3>
      </div>
    </>
  );
};
