import { useMsal } from '@azure/msal-react';
import { GiEntryDoor } from 'react-icons/gi';

function handleLogout(instance) {
  instance
    .logoutRedirect({
      postLogoutRedirectUri: '/',
    })
    .catch((e) => {
      console.error(e);
    });
}

// Renders a button which, when selected, will open a popup for login
export const BtnLogout = () => {
  const { instance } = useMsal();

  return (
    <>
      <div
        className='btn-util-nav-link'
        role='button'
        aria-label='Logout'
        title='Logout'
        onClick={() => {
          handleLogout(instance);
        }}>
        <GiEntryDoor />
        <h3 className='fs-xs'>Logout</h3>
      </div>
    </>
  );
};
