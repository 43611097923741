import { useState, forwardRef, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { GiCalendar } from 'react-icons/gi';
import { CategoriesContext } from '../../contexts/CategoriesContext';
import { PopupContext } from '../../contexts/PopupContext';
import { TasksContext } from '../../contexts/TasksContext';
import { getDefaultTaskCategoryId } from './TaskCategoriesHelper';

const EditTaskForm = () => {
  const { taskCategories } = useContext(CategoriesContext);
  const { editIsOpen, setEditIsOpen, hideEditModal } = useContext(PopupContext);
  const { editTask, taskToEdit } = useContext(TasksContext);

  const [editTaskTitle, setEditTaskTitle] = useState(taskToEdit ? taskToEdit.taskTitle : '');
  const [editTaskDate, setEditTaskDate] = useState(taskToEdit ? new Date(taskToEdit.taskDate) : new Date());
  const [editTaskCategory, setEditTaskCategory] = useState(
    taskToEdit ? taskToEdit.taskCategoryId : getDefaultTaskCategoryId(taskCategories)
  );

  const onSubmit = (e) => {
    e.preventDefault();

    if (!editTaskTitle) {
      alert('Add a new task');
      return;
    }

    const updatedTask = {
      id: taskToEdit.id,
      taskTitle: editTaskTitle,
      taskDate: editTaskDate,
      taskCategoryId: editTaskCategory,
    };

    editTask(updatedTask);
    setEditTaskTitle('');
    setEditTaskDate(new Date());
    setEditTaskCategory(getDefaultTaskCategoryId(taskCategories));
    setEditIsOpen(!editIsOpen);
  };

  const CustomBtnCalendar = forwardRef(({ onClick }, ref) => (
    <div
      className='btn-calendar d-flex align-items-center link-primary'
      type='button'
      aria-label='Calendar'
      title='Calendar'
      onClick={onClick}
      ref={ref}
    >
      <GiCalendar />
    </div>
  ));

  return (
    <>
      <form onSubmit={onSubmit} className='edit-task-form custom-form-style d-flex gap-3 flex-column'>
        <div className='edit-task-input d-flex gap-2 flex-column'>
          <label className='text-primary'>Edit task</label>

          <div className='d-flex gap-2'>
            <input
              className='form-control input-style'
              type='text'
              placeholder='Edit task'
              aria-label='Edit task input'
              value={editTaskTitle}
              onChange={(e) => setEditTaskTitle(e.target.value)}
            />

            <div className='date-picker-content'>
              <DatePicker
                customInput={<CustomBtnCalendar />}
                popperClassName='datePickerCustomClass'
                value={editTaskDate}
                selected={editTaskDate}
                onChange={(date) => setEditTaskDate(date)}
              />
            </div>
          </div>
        </div>

        <div className='edit-category-select d-flex gap-2 flex-column'>
          <label className='text-primary'>Select a category</label>

          <select
            className='form-select'
            value={editTaskCategory}
            aria-label='Edit category'
            onChange={(e) => setEditTaskCategory(e.target.value)}
          >
            {taskCategories.map((taskCategory) => {
              return (
                <option key={taskCategory.id} value={taskCategory.id}>
                  {taskCategory.taskCategoryTitle}
                </option>
              );
            })}
          </select>
        </div>

        <div className='edit-form-btns btn-border d-flex gap-2 justify-content-center'>
          <div className='btn-save-edit-task btn btn-sm btn-border-inner'>
            <input className='link-primary' type='submit' value='Save' />
          </div>

          <div className='btn-close-edit-task btn btn-sm btn-border-inner'>
            <input className='link-primary' type='button' value='Close' onClick={hideEditModal} />
          </div>
        </div>
      </form>
    </>
  );
};

export default EditTaskForm;
