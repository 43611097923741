import { useState, useEffect } from 'react';

const DateTimeDisplay = () => {
  // Display date
  const today = new Date();
  const dateFull = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };

  // Display time
  const [timeDisplay, setTimeDisplay] = useState(new Date(new Date()));
  const hhmm = {
    hour: '2-digit',
    minute: '2-digit',
  };

  useEffect(() => {
    const timer = setInterval(() => setTimeDisplay(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <>
      <div className='date-time-display-wrapper d-flex gap-2 justify-content-center align-items-center text-primary font-styled px-3'>
        <p>{today.toLocaleDateString('en-US', dateFull)}</p>
        <p>{timeDisplay.toLocaleTimeString('en-US', hhmm)}</p>
      </div>
    </>
  );
};

export default DateTimeDisplay;
