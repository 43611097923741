import React from 'react';
import './styles/css/styles.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <div className='root-wrapper bg-gray-500'>
      <App msalInstance={msalInstance} />
    </div>
  </Router>
);
