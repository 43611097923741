import { Link } from 'react-router-dom';
import useWindowSize from '../../services/useWindowSize';
import SidebarNav from '../navigation/SidebarNav';
import DateTimeDisplay from '../utilities/DateTimeDisplay';

const Header = () => {
  // Define screen size limit
  const [width] = useWindowSize();
  const isSmallDevice = width < 576;

  return (
    <>
      <div className='header-content d-flex justify-content-between align-items-center'>
        <Link to={'/'} className='header-logo'>
          <h1 className='fs-3 font-styled text-dark'>
            <span className='text-primary'>Fun </span>
            <span className='text-tertiary'>With </span>
            <span className='text-secondary'>Tasks </span>
          </h1>
        </Link>

        {!isSmallDevice && <DateTimeDisplay />}

        <SidebarNav />
      </div>
    </>
  );
};

export default Header;
