import { useContext } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { PopupContext } from '../../contexts/PopupContext';

const BtnAddProject = () => {
  const { showModal } = useContext(PopupContext);

  return (
    <>
      <button
        className='d-flex align-items-center gap-2 btn btn-primary btn-sm'
        type='button'
        aria-label='Add Project'
        title='Add Project'
        onClick={showModal}>
        <p className='link-light'>Add Project</p>
        <FiPlusCircle className='fs-4 link-light' />
      </button>
    </>
  );
};

export default BtnAddProject;
