import { useContext } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { TasksFiltersContext } from '../../contexts/TasksFiltersContext';

const TasksList = () => {
  const { filteredTaskTitle, filteredCategoryTitle, filteredTasksList } =
    useContext(TasksFiltersContext);

  return (
    <>
      {/* Display the list of tasks according to the filter */}
      <div className='filtered-tasks-list d-flex gap-2 flex-column'>
        <h5 className='filtered-task-title fs-5 text-primary font-styled px-3'>
          {filteredTaskTitle}
          <RiArrowRightSLine />
          {filteredCategoryTitle}
        </h5>

        <div className='tasks-list p-3'>{filteredTasksList}</div>
      </div>
    </>
  );
};

export default TasksList;
