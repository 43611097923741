import { RiPushpinFill } from 'react-icons/ri';
import { VscCircleFilled } from 'react-icons/vsc';

const PinIcon = ({ task, categoryColor }) => {
  return (
    <>
      <div className='pin-icon'>
        <RiPushpinFill
          className={`icon-pin fs-2 ${
            task.isComplete ? 'text-gray-500' : categoryColor
          }`}
        />
        <VscCircleFilled
          className={`icon-hole ${
            task.isComplete ? 'text-gray-500' : 'text-gray-600'
          }`}
        />
      </div>
    </>
  );
};

export default PinIcon;
