import { useState, useContext } from 'react';
import { CategoriesContext } from '../../contexts/CategoriesContext';

const AddTaskCategoryForm = () => {
  const { addTaskCategory } = useContext(CategoriesContext);

  const [taskCategoryTitle, setTaskCategoryTitle] = useState('');
  const [taskCategoryIcon, setTaskCategoryIcon] =
    useState('RiChatFollowUpLine');
  const [taskCategoryColor, setTaskCategoryColor] = useState('blue-cat');

  const onSubmit = (e) => {
    e.preventDefault();

    if (!taskCategoryTitle) {
      alert('Add a new category');
      return;
    }

    const newTaskCategory = {
      taskCategoryTitle,
      taskCategoryIcon,
      taskCategoryColor,
    };

    addTaskCategory(newTaskCategory);
    setTaskCategoryTitle('');
    setTaskCategoryIcon('RiChatFollowUpLine');
    setTaskCategoryColor('blue-cat');
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        className='add-task-category-form custom-form-style d-flex flex-column gap-3'>
        <div className='add-task-category-input d-flex gap-2'>
          <input
            className='form-control input-style'
            type='text'
            placeholder='Add task category'
            value={taskCategoryTitle}
            onChange={(e) => setTaskCategoryTitle(e.target.value)}
          />
        </div>

        <div className='add-task-category-select'>
          <select
            defaultValue={taskCategoryIcon}
            className='form-select'
            onChange={(e) => setTaskCategoryIcon(e.target.value)}>
            <option value='RiInboxArchiveLine'>Icon 1</option>
            <option value='MdOutlinePendingActions'>Icon 2</option>
            <option value='RiCalendarCheckLine'>Icon 3</option>
            <option value='CgCalendarToday'>Icon 4</option>
            <option value='RiChatFollowUpLine'>Icon 5</option>
          </select>
        </div>

        <div className='add-task-category-select'>
          <select
            defaultValue={taskCategoryColor}
            className='form-select option-style'
            onChange={(e) => setTaskCategoryColor(e.target.value)}>
            <option value='blue-cat'>Blue</option>
            <option value='red-cat'>Red</option>
            <option value='yellow-cat'>Yellow</option>
            <option value='green-cat'>Green</option>
            <option value='blue-2-cat'>Blue 2</option>
            <option value='red-2-cat'>Red 2</option>
            <option value='yellow-2-cat'>Yellow 2</option>
            <option value='green-2-cat'>Green 2</option>
          </select>
        </div>

        <div className='add-form-btns btn-border d-flex gap-2 justify-content-center'>
          <div className='btn-save-task btn btn-sm btn-border-inner'>
            <input className='link-primary' type='submit' value='Save' />
          </div>
        </div>
      </form>
    </>
  );
};

export default AddTaskCategoryForm;
