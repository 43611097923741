import { AuthenticatedTemplate } from '@azure/msal-react';
import useWindowSize from '../../services/useWindowSize';

import DateTimeDisplay from '../utilities/DateTimeDisplay';
import UnauthenticatedWarning from '../utilities/UnauthenticatedWarning';

// import BtnAddProject from '../buttons/BtnAddProject';
// import ProjectsList from '../projects/ProjectsList';

const SettingsPage = ({ pageTitle }) => {
  // Define screen size limit
  const [width] = useWindowSize();
  const isSmallDevice = width < 576;

  return (
    <>
      <AuthenticatedTemplate>
        {isSmallDevice && (
          <>
            <DateTimeDisplay />
            <hr />
          </>
        )}

        <div className='page-header d-flex justify-content-between align-items-center px-3'>
          <h2 className='page-title'>{pageTitle}</h2>

          {/* <BtnAddProject /> */}
        </div>

        <hr />

        {/* <ProjectsList /> */}
      </AuthenticatedTemplate>

      <UnauthenticatedWarning />
    </>
  );
};

export default SettingsPage;
