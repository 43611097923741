import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { BtnLogin } from '../buttons/BtnLogin';
import { UserGreeting } from '../utilities/UserGreeting';

const LandingPage = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <UserGreeting />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className='container-fluid'>
          <section className='vh-80 align-all flex-column text-dark'>
            <p className='display-5 font-styled text-center text-blue-200 mb-3'>
              Welcome!
            </p>
            <p className='fs-5 text-center lh-sm'>
              Get your life organized <br /> and have some
            </p>

            <h1 className='display-4 font-styled text-dark px-3 pt-3'>
              <span className='text-primary'>Fun </span>
              <span className='text-tertiary'>With </span>
              <span className='text-secondary'>Tasks </span>
            </h1>

            <div className='display-3 link-primary my-3'>
              <BtnLogin />
            </div>

            <p className='fs-6 text-dark text-center m-0'>
              Sign in or Register
            </p>
          </section>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default LandingPage;
