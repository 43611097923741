import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { NavLink } from 'react-router-dom';
import { GiSettingsKnobs } from 'react-icons/gi';
import { RiHomeSmileLine } from 'react-icons/ri';

import { BtnLogin } from '../buttons/BtnLogin';
import { BtnLogout } from '../buttons/BtnLogout';

const UtilNav = () => {
  return (
    <nav className='d-flex flex-column gap-3'>
      <UnauthenticatedTemplate>
        <ul className='nav'>
          <li className='nav-item fs-1'>
            <BtnLogin />
            <h3 className='fs-xs text-center link-light'>Login</h3>
          </li>
        </ul>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <ul className='nav d-flex flex-nowrap justify-content-around gap-1'>
          <li className='nav-item fs-1'>
            <NavLink
              to='/'
              aria-label='Homepage'
              title='Homepage'
              className='util-nav-link'>
              <RiHomeSmileLine />
              <h3 className='fs-xs'>Home</h3>
            </NavLink>
          </li>

          <li className='nav-item fs-1'>
            <NavLink
              to='/settings'
              aria-label='Settings'
              title='Settings'
              className='util-nav-link'>
              <GiSettingsKnobs />
              <h3 className='fs-xs'>Settings</h3>
            </NavLink>
          </li>

          <li className='nav-item fs-1'>
            <BtnLogout />
          </li>
        </ul>
      </AuthenticatedTemplate>
    </nav>
  );
};

export default UtilNav;
