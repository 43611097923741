import { useState } from 'react';

const Accordion = ({ title, children, iconOpen, iconClose }) => {
  const [isTabOpen, setIsTabOpen] = useState(false);

  return (
    <>
      <div className='accordion-wrapper d-flex flex-column justify-content-between align-items-between'>
        <div
          className={`accordion-title d-flex justify-content-between align-items-center ${
            isTabOpen ? 'open' : ''
          }`}
          onClick={() => setIsTabOpen(!isTabOpen)}>
          <h2 className='font-styled fs-6 text-light'>{title}</h2>

          <div
            className='accordion-icon d-flex align-item-center'
            type='button'
            aria-label='Add new item'
            title='Add new item'>
            {!isTabOpen && (
              <>
                <div className='fs-5 link-light'>{iconOpen}</div>
              </>
            )}

            {isTabOpen && (
              <>
                <div className='fs-5 link-light'>{iconClose}</div>
              </>
            )}
          </div>
        </div>

        <div className={`accordion-item ${!isTabOpen ? 'collapsed' : ''}`}>
          <div className='accordion-content py-3'>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
