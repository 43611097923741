import { LogLevel } from '@azure/msal-browser';

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_SignUpAndSignIn',
    resetPassword: 'B2C_1_PasswordReset',
    editProfile: 'B2C_1_ProfileEditing',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://funwithtasks.b2clogin.com/funwithtasks.onmicrosoft.com/B2C_1_SignUpAndSignIn',
    },
    forgotPassword: {
      authority:
        'https://funwithtasks.b2clogin.com/funwithtasks.onmicrosoft.com/B2C_1_PasswordReset',
    },
    editProfile: {
      authority:
        'https://funwithtasks.b2clogin.com/funwithtasks.onmicrosoft.com/B2C_1_ProfileEditing',
    },
  },
  authorityDomain: 'funwithtasks.b2clogin.com',
};

export const msalConfig = {
  auth: {
    clientId: '62c7f502-d747-4b90-afa1-12dd204f5391',
    clientSecret: '.iJ7Q~ujrY41UXpzY0Y7.q.EENMeI1e7HFApk',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.error(`Invalid log level passed to log function: ${level}`);
            console.error(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile'],
  loginHint: 'hint@funwithtasks.onmicrosoft.com',
};

export const silentRequest = {
  scopes: ['https://funwithtasks.onmicrosoft.com/tasks-api/tasks'],
  loginHint: 'hint@funwithtasks.onmicrosoft.com',
};
