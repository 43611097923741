import { createContext, useState, useEffect, useContext } from 'react';
import api from '../api/tasks';
import { PopupContext } from './PopupContext';
import { useMsal } from '@azure/msal-react';
import { getToken, getApiConfig } from '../utilities/SecurityUtils';

export const TasksContext = createContext();

const TasksContextProvider = ({ children }) => {
  const { instance, accounts } = useMsal();

  const { deleteIsOpen, setDeleteIsOpen, editIsOpen, setEditIsOpen } = useContext(PopupContext);

  const [tasks, setTasks] = useState([]);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [taskToEdit, setTaskToEdit] = useState(null);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!instance || !accounts || accounts.length === 0) {
        setTasks([]);
        return;
      }
      try {
        const token = await await getToken(instance, accounts);
        const config = getApiConfig(token);
        const res = await api.get('/tasks/', config);
        setTasks(res.data);
      } catch (err) {
        console.log(`Error: ${err.message}`);
      }
    };
    fetchTasks();
  }, [instance, accounts]);

  // Add task function
  const addTask = async (newTask) => {
    try {
      const token = await getToken(instance, accounts);
      const config = getApiConfig(token);
      const res = await api.post('/tasks', newTask, config);
      setTasks([...tasks, res.data]);
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  // Delete task function
  const deleteTask = async (id) => {
    try {
      const token = await getToken(instance, accounts);
      const config = getApiConfig(token);
      await api.delete(`/tasks/${id}`, config);
      setTasks(tasks.filter((task) => task.id !== id));
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  // Define task to delete
  const getTaskToDelete = (id) => {
    return tasks.find((task) => task.id === id);
  };

  const handleDeleteTask = (id) => {
    setTaskToDelete(getTaskToDelete(id));
    setDeleteIsOpen(!deleteIsOpen);
  };

  // Edit task
  const editTask = async (task) => {
    try {
      const token = await getToken(instance, accounts);
      const config = getApiConfig(token);
      await api.put(`/tasks/${task.id}`, task, config);
      setTasks(tasks.map((tsk) => (tsk.id === task.id ? { ...task } : tsk)));
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  // Define taskToEdit
  const getTaskToEdit = (id) => {
    return tasks.find((task) => task.id === id);
  };

  const handleEditTask = (id) => {
    setTaskToEdit(getTaskToEdit(id));
    setEditIsOpen(!editIsOpen);
  };

  // Complete task
  const completeTask = async (id) => {
    try {
      const token = await getToken(instance, accounts);
      const config = getApiConfig(token);
      let tsk;
      const updatedTasks = tasks.map((task) => {
        if (id === task.id) {
          tsk = { ...task, isComplete: !task.isComplete };
          return tsk;
        }
        return task;
      });
      await api.put(`/tasks/${tsk.id}`, tsk, config);
      setTasks(updatedTasks);
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  return (
    <TasksContext.Provider
      value={{
        tasks,
        setTasks,
        addTask,
        deleteTask,
        completeTask,
        editTask,
        taskToDelete,
        handleDeleteTask,
        taskToEdit,
        handleEditTask,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export default TasksContextProvider;
