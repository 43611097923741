import { useContext } from 'react';
import { RiDeleteBinFill } from 'react-icons/ri';
import { TasksContext } from '../../contexts/TasksContext';

const BtnOpenDeleteModal = ({ task }) => {
  const { handleDeleteTask } = useContext(TasksContext);

  return (
    <>
      <RiDeleteBinFill
        className={`btn-round p-1 fs-2 ${
          task.isComplete ? 'text-gray-500' : 'text-gray-600'
        } `}
        role='button'
        aria-label='Delete Task'
        title='Delete Task'
        onClick={() => handleDeleteTask(task.id)}
      />
    </>
  );
};

export default BtnOpenDeleteModal;
