import { createContext, useState } from 'react';

export const PopupContext = createContext();

const PopupContextProvider = ({ children }) => {
  // Modal functions for tasks
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };

  // Modal functions for edit tasks
  const [editIsOpen, setEditIsOpen] = useState(false);
  const showEditModal = () => {
    setEditIsOpen(true);
  };
  const hideEditModal = () => {
    setEditIsOpen(false);
  };

  // Modal functions for delete tasks
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const showDeleteModal = () => {
    setDeleteIsOpen(true);
  };
  const hideDeleteModal = () => {
    setDeleteIsOpen(false);
  };

  return (
    <PopupContext.Provider
      value={{
        isOpen,
        setIsOpen,
        showModal,
        hideModal,
        editIsOpen,
        setEditIsOpen,
        showEditModal,
        hideEditModal,
        deleteIsOpen,
        setDeleteIsOpen,
        showDeleteModal,
        hideDeleteModal,
      }}>
      {children}
    </PopupContext.Provider>
  );
};

export default PopupContextProvider;
