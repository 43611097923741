import { useContext } from 'react';
import { TasksContext } from '../../contexts/TasksContext';
import { RiBallPenFill } from 'react-icons/ri';

const BtnEditTask = ({ task }) => {
  const { handleEditTask } = useContext(TasksContext);

  return (
    <>
      <RiBallPenFill
        className={`btn-round p-1 fs-2 ${
          task.isComplete ? 'text-gray-500' : 'text-gray-600'
        } `}
        role='button'
        aria-label='Edit Task'
        title='Edit Task'
        onClick={() => handleEditTask(task.id)}
      />
    </>
  );
};

export default BtnEditTask;
