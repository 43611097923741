import { createContext, useState } from 'react';

export const FiltersContext = createContext();

const FiltersContextProvider = ({ children }) => {
  const [selectedTaskFilter, setSelectedTaskFilter] = useState('All Tasks');
  const [selectedTaskCategoryFilter, setSelectedTaskCategoryFilter] =
    useState('All Categories');

  return (
    <FiltersContext.Provider
      value={{
        selectedTaskFilter,
        setSelectedTaskFilter,
        selectedTaskCategoryFilter,
        setSelectedTaskCategoryFilter,
      }}>
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersContextProvider;
