import { Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import CategoriesContextProvider from './contexts/CategoriesContext';
import FiltersContextProvider from './contexts/FiltersContext';
import PopupContextProvider from './contexts/PopupContext';
import TasksContextProvider from './contexts/TasksContext';
import TasksFiltersContextProvider from './contexts/TasksFiltersContext';

import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import LandingPage from './components/pages/LandingPage';
import ProjectsPage from './components/pages/ProjectsPage';
import SettingsPage from './components/pages/SettingsPage';
import TasksPage from './components/pages/TasksPage';

function App({ msalInstance }) {
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <PopupContextProvider>
          <FiltersContextProvider>
            <TasksContextProvider>
              <CategoriesContextProvider>
                <TasksFiltersContextProvider>
                  <div className='app-container container p-3'>
                    <div className='app-wrapper'>
                      <header className='header-wrapper px-3 pt-3'>
                        <Header />
                      </header>

                      <hr />

                      <main className='main-wrapper'>
                        <Routes>
                          <Route path='/' element={<LandingPage />} />
                          <Route
                            path='/settings'
                            element={<SettingsPage pageTitle='Settings' />}
                          />
                          <Route
                            path='/tasks'
                            element={<TasksPage pageTitle='Tasks' />}
                          />
                          <Route
                            path='/projects'
                            element={<ProjectsPage pageTitle='Projects' />}
                          />
                        </Routes>
                      </main>

                      <hr />

                      <footer className='footer-wrapper pb-3'>
                        <Footer />
                      </footer>
                    </div>
                  </div>
                </TasksFiltersContextProvider>
              </CategoriesContextProvider>
            </TasksContextProvider>
          </FiltersContextProvider>
        </PopupContextProvider>
      </MsalProvider>
    </>
  );
}

export default App;
