import { NavLink } from 'react-router-dom';
import { FaProjectDiagram } from 'react-icons/fa';
import { FaTasks } from 'react-icons/fa';

const FavoritesNav = () => {
  return (
    <>
      <ul className='nav d-flex flex-column gap-2'>
        <li className='nav-item'>
          <NavLink
            to='/tasks'
            aria-label='Tasks'
            title='Tasks'
            className='main-nav-link gap-2 py-1'>
            <FaTasks className='fs-6' />
            <h3 className='fs-6'>Tasks</h3>
          </NavLink>
        </li>

        <li className='nav-item'>
          <NavLink
            to='/projects'
            aria-label='Projects'
            title='Projects'
            className='main-nav-link gap-2 py-1'>
            <FaProjectDiagram className='fs-6' />
            <h3 className='fs-6'>Projects</h3>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default FavoritesNav;
